/* FADE */

.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}

.fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: 300ms;
}

/* SLIDE LEFT */

.slide-left-enter,
.slide-left-appear {
  transform: translateX(100%);
}

.slide-left-enter-active,
.slide-left-appear-active {
  transform: translateX(0%);
  transition: 0.5s;
}

.slide-left-exit {
  opacity: 1;
}

.slide-left-exit-active {
  transform: translateX(100%);
  transition: 0.5s;
}

/* notificaiton */

.csst-notification-enter,
.csst-notification-appear {
  opacity: 0;
  transform: scale(0.8);
}

.csst-notification-enter-active,
.csst-notification-appear-active {
  opacity: 1;
  transform: scale(1);
  transition: 0.25s;
}

.csst-notification-exit {
  opacity: 1;
}

.csst-notification-exit-active {
  opacity: 0;
  transition: 1s;
}
