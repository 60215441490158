:root {
  --color-primary: #01baef;
  --color-secondary: #0b4f6c;
  --color-light: #fbfbff;
  --color-dark: #040f16;

  --color-success: #56e39f;
  --color-warning: #f0c808;
  --color-error: #b80c09;

  /* --color-primary-100: #004c62;
  --color-primary-200: #016e8d;
  --color-primary-300: #0189b1;
  --color-primary-400: #01a1cf;
  --color-primary-500: #01baef;
  --color-primary-600: #32d1fe;
  --color-primary-700: #7ce1fe;
  --color-primary-800: #b4eeff;
  --color-primary-900: #ebfaff; */

  --color-neutral-100: #333333;
  --color-neutral-200: #4d4d4d;
  --color-neutral-300: #666666;
  --color-neutral-400: #7a7a7a;
  --color-neutral-500: #999999;
  --color-neutral-600: #b3b3b3;
  --color-neutral-700: #cccccc;
  --color-neutral-800: #e0e0e0;
  --color-neutral-900: #f2f2f2;

  --color-primary-100: #00566e;
  --color-primary-200: #017799;
  --color-primary-300: #0199c4;
  --color-primary-400: #01baef;
  --color-primary-500: #2fc6f2;
  --color-primary-600: #5cd3f5;
  --color-primary-700: #8adff8;
  --color-primary-800: #b8ecfb;
  --color-primary-900: #e6f8fd;

  --color-success-100: #286849;
  --color-success-200: #379166;
  --color-success-300: #47ba82;
  --color-success-400: #56e39f;
  --color-success-500: #74e8b0;
  --color-success-600: #93edc2;
  --color-success-700: #b1f2d3;
  --color-success-800: #d0f7e4;
  --color-success-900: #eefcf5;

  --color-warning-100: #6e5c04;
  --color-warning-200: #9a8005;
  --color-warning-300: #c5a407;
  --color-warning-400: #f0c808;
  --color-warning-500: #f3d234;
  --color-warning-600: #f5dc61;
  --color-warning-700: #f8e68d;
  --color-warning-800: #fbf0ba;
  --color-warning-900: #fefae6;

  --color-error-100: #550604;
  --color-error-200: #760806;
  --color-error-300: #970a07;
  --color-error-400: #b80c09;
  --color-error-500: #c53835;
  --color-error-600: #d26362;
  --color-error-700: #de8f8e;
  --color-error-800: #ebbbba;
  --color-error-900: #f8e7e6;

  --spacing-small: 10px;
  --spacing-medium: 20px;
  --spacing-large: 30px;
  --spacing-extra-large: 45px;

  --box-shadow-1: 1px 1px 4px rgba(0, 0, 0, 0.2), 1px 1px 1px rgba(0, 0, 0, 0.4);
  --box-shadow-2: 2px 2px 6px rgba(0, 0, 0, 0.2), 1px 1px 2px rgba(0, 0, 0, 0.4);
  --box-shadow-3: 3px 3px 10px rgba(0, 0, 0, 0.2),
    1px 1px 3px rgba(0, 0, 0, 0.4);

  font-family: Roboto, Arial, sans-serif;
  color: white;
  font-size: 16px;

  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

h1 {
  text-align: center;
}

a {
  color: var(--color-primary-600);
  text-decoration: none;
}

a:hover {
  color: var(--color-primary-800);
}

body {
  background-color: var(--color-neutral-400);

  overflow-y: scroll;
}

.large-header {
  font-size: 2rem;
  line-height: 75px;
}

.medium-header {
  font-size: 1.6rem;
  line-height: 60px;
}

.small-header {
  font-size: 1.4rem;
  line-height: 45px;
}
